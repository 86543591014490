import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"

Rails.start()
Turbolinks.start()

const images = require.context('../images/lp', true)
const imagePath = (name) => images(name, true)

import "../stylesheets/lp/slick/slick.css"
import "../stylesheets/lp/slick/slick-theme.css"
